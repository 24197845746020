<!--
* @description:
* @fileName typeEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="500px"
    @close="close"
  >
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="渠道类型" prop="channel">
        <el-radio-group v-model="form.channel">
          <el-radio
            v-for="(item, index) in channelList"
            :key="index"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { saveType, editType } from '@/api/active'

  export default defineComponent({
    name: 'TypeEdit',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        channelList: [
          {
            label: '官网',
            value: 'PC',
          },
          {
            label: 'me杭州小程序',
            value: 'WX_MEHZ',
          },
          {
            label: '数贸会小程序',
            value: 'WX_GDTE',
          },
        ],
        form: {},
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入分类名称' },
          ],
          channel: [
            { required: true, trigger: 'change', message: '请选择渠道类型' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
      })

      const showEdit = async (row) => {
        if (!row) {
          state.title = '添加'
        } else {
          state.title = '编辑'
          state.form = Object.assign({}, row)
        }
        state.dialogFormVisible = true
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {}
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            if (state.title == '编辑') {
              await editType(state.form)
            } else {
              await saveType(state.form)
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }
      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }
</style>
